import { put, takeLatest, call } from "redux-saga/effects";
import {
  FINALIZE_APPOINTMENT,
  finalizeAppointmentSuccess,
  finalizeAppointmentFailure,
} from "./finalizeAppointment.actions";
import API from "../../components/api-authorization/API";

function* finalizeAppointment({ customerId }) {
  try {
    yield call(API.post, `/api/appointment/${customerId}`);

    yield put(finalizeAppointmentSuccess());
  } catch (error) {
    console.log(error);
    yield put(finalizeAppointmentFailure());
  }
}

export default function* () {
  yield takeLatest(FINALIZE_APPOINTMENT, finalizeAppointment);
}
