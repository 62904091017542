export const FINALIZE_APPOINTMENT = "FINALIZE_APPOINTMENT";
export const FINALIZE_APPOINTMENT_SUCCESS = "FINALIZE_APPOINTMENT_SUCCESS";
export const FINALIZE_APPOINTMENT_FAILURE = "FINALIZE_APPOINTMENT_FAILURE";

export const finalizeAppointment = (customerId) => ({
  type: FINALIZE_APPOINTMENT,
  customerId
});
export const finalizeAppointmentSuccess = (payload) => ({
  type: FINALIZE_APPOINTMENT_SUCCESS,
  payload,
});
export const finalizeAppointmentFailure = (errors) => ({
  type: FINALIZE_APPOINTMENT_FAILURE,
  errors,
});
