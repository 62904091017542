import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, FormGroup, Label, Input, Container } from 'reactstrap';
import { login, loginClear } from '../store/login/login.actions';
const Tankwirt = require('../assets/tankwirt_logo.png');

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  const { success, loading } = useSelector((state) => state.request.login);

  useEffect(() => {
    dispatch(loginClear());
  }, [success]);

  return (
    <Container>
      <img src={Tankwirt} height={200} />
      <Form className="col-12 col-md-6" onSubmit={onSubmit}>
        <h1 style={{ marginBottom: 20 }}>Login</h1>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="text"
            name="email"
            required
            value={email}
          />
        </FormGroup>
        <FormGroup>
          <Label for="password">Passwort</Label>
          <Input
            name="password"
            type="password"
            required
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </FormGroup>
        <Button
          disabled={loading}
          type="submit"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Login
        </Button>
      </Form>
    </Container>
  );
};

export default Login;
