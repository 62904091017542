import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarText, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            
            <NavbarBrand tag={Link} to="/">Corona Drive-In Gelsdorf</NavbarBrand>
           
            <NavbarText>Tankwirt: Dürener Straße 56, 53501 Gelsdorf</NavbarText>
            <NavbarText>Tel: 0 22 25 / 704 80 80</NavbarText>
           
          </Container>
        </Navbar>
      </header>
    );
  }
}
