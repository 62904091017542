import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
} from "../reducer.helper";

import {
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAILURE,
  UPDATE_APPOINTMENT_CLEAR
} from "./updateAppointment.actions";

export default createReducer(
  {
    [UPDATE_APPOINTMENT]: defaultRequest,
    [UPDATE_APPOINTMENT_SUCCESS]: defaultSuccess,
    [UPDATE_APPOINTMENT_FAILURE]: defaultError,
    [UPDATE_APPOINTMENT_CLEAR]: () => defaultInitialState,
  },
  defaultInitialState
);
