import axios from 'axios';
import Config from './config';

const API = axios.create({
  baseURL: Config.ApiUrl,
  responseType: 'json',
});

API.interceptors.request.use(async (request) => {
  request.headers = {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`
  };
  return request;
});

export default API;
