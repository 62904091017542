export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS';
export const CREATE_APPOINTMENT_FAILURE = 'CREATE_APPOINTMENT_FAILURE';
export const CREATE_APPOINTMENT_CLEAR = 'CREATE_APPOINTMENT_CLEAR';

export const createAppointment = (
  firstName,
  lastName,
  email,
  dateOfBirth,
  dateForTest,
  street,
  houseNumber,
  postCode,
  location,
  phoneNumber
) => ({
  type: CREATE_APPOINTMENT,
  firstName,
  lastName,
  email,
  dateOfBirth,
  dateForTest,
  street,
  houseNumber,
  postCode,
  location,
  phoneNumber
});
export const createAppointmentSuccess = (payload) => ({
  type: CREATE_APPOINTMENT_SUCCESS,
  payload,
});
export const createAppointmentFailure = (errors) => ({
  type: CREATE_APPOINTMENT_FAILURE,
  errors,
});
export const createAppointmentClear = () => ({
  type: CREATE_APPOINTMENT_CLEAR,
});
