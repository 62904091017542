import { put, takeLatest, call } from 'redux-saga/effects';
import {
    GET_SENT_RESULTS_COUNT,
    getSentResultsCountSuccess,
    getSentResultsCountFailure,
} from './getSentResultsCount.actions';
import API from '../../components/api-authorization/API';

function* getSentResultsCount() {
    try {
        const { data } = yield call(API.get, '/api/appointment/sentResultsCount',);

        yield put(getSentResultsCountSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(getSentResultsCountFailure());
    }
}

export default function* () {
    yield takeLatest(GET_SENT_RESULTS_COUNT, getSentResultsCount);
}