import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
} from "../reducer.helper";

import {
  GET_ALL_APPOINTMENTS,
  GET_ALL_APPOINTMENTS_SUCCESS,
  GET_ALL_APPOINTMENTS_FAILURE,
} from "./getAllAppointments.actions";

export default createReducer(
  {
    [GET_ALL_APPOINTMENTS]: defaultRequest,
    [GET_ALL_APPOINTMENTS_SUCCESS]: defaultSuccess,
    [GET_ALL_APPOINTMENTS_FAILURE]: defaultError,
  },
  defaultInitialState
);
