export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAILURE = 'UPDATE_APPOINTMENT_FAILURE';
export const UPDATE_APPOINTMENT_CLEAR = 'UPDATE_APPOINTMENT_CLEAR';

export const updateAppointment = (
  id,
  firstName,
  lastName,
  email,
  dateOfBirth,
  dateForTest,
  testerName,
  consentGiven,
  dateOfTransmission,
  formOfConsent,
  hasCovid,
  street,
  houseNumber,
  postCode,
  location,
  phoneNumber
) => ({
  type: UPDATE_APPOINTMENT,
  id,
  firstName,
  lastName,
  email,
  dateOfBirth,
  dateForTest,
  testerName,
  consentGiven,
  dateOfTransmission,
  formOfConsent,
  hasCovid,
  street,
  houseNumber,
  postCode,
  location,
  phoneNumber
});
export const updateAppointmentSuccess = (payload) => ({
  type: UPDATE_APPOINTMENT_SUCCESS,
  payload,
});
export const updateAppointmentFailure = (errors) => ({
  type: UPDATE_APPOINTMENT_FAILURE,
  errors,
});
export const updateAppointmentClear = () => ({
  type: UPDATE_APPOINTMENT_CLEAR,
});
