export const GET_ALL_APPOINTMENTS = "GET_ALL_APPOINTMENTS";
export const GET_ALL_APPOINTMENTS_SUCCESS = "GET_ALL_APPOINTMENTS_SUCCESS";
export const GET_ALL_APPOINTMENTS_FAILURE = "GET_ALL_APPOINTMENTS_FAILURE";

export const getAllAppointments = () => ({
  type: GET_ALL_APPOINTMENTS,
});
export const getAllAppointmentsSuccess = (payload) => ({
  type: GET_ALL_APPOINTMENTS_SUCCESS,
  payload,
});
export const getAllAppointmentsFailure = (errors) => ({
  type: GET_ALL_APPOINTMENTS_FAILURE,
  errors,
});
