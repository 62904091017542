import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
} from "../reducer.helper";

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_CLEAR,
} from "./login.actions";

export default createReducer(
  {
    [LOGIN]: defaultRequest,
    [LOGIN_SUCCESS]: defaultSuccess,
    [LOGIN_FAILURE]: defaultError,
    [LOGIN_CLEAR]: () => defaultInitialState,
  },
  defaultInitialState
);
