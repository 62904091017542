import { put, takeLatest, call } from 'redux-saga/effects';
import {
  CREATE_APPOINTMENT,
  createAppointmentSuccess,
  createAppointmentFailure,
} from './createAppointment.actions';
import API from '../../components/api-authorization/API';

function* createAppointment({
  firstName,
  lastName,
  email,
  dateOfBirth,
  dateForTest,
  street,
  houseNumber,
  postCode,
  location,
  phoneNumber
}) {
  try {
    yield call(API.post, '/api/appointment', {
      firstName,
      lastName,
      email,
      dateOfBirth,
      dateForTest: dateForTest ? dateForTest : null,
      street,
      houseNumber,
      postCode,
      location,
      phoneNumber
    });

    yield put(createAppointmentSuccess());
  } catch (error) {
    console.log(error);
    yield put(createAppointmentFailure());
  }
}

export default function* () {
  yield takeLatest(CREATE_APPOINTMENT, createAppointment);
}
