import { combineReducers } from "redux";
import createAppointment from "./createAppointment/createAppointment.reducer";
import getAllAppointments from "./getAllAppointments/getAllAppointments.reducer";
import updateAppointment from "./updateAppointment/updateAppointment.reducer";
import finalizeAppointment from "./finalizeAppointment/finalizeAppointment.reducer";
import appointment from "./appointment.reducer";
import login from "./login/login.reducer";
import getSentResultsCount from "./getSentResultsCount/getSentResultsCount.reducer"

export default combineReducers({
    request: combineReducers({
        createAppointment,
        getAllAppointments,
        updateAppointment,
        finalizeAppointment,
        login,
        getSentResultsCount
    }),
    appointment,
});