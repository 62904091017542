import React, { Component } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "./NavMenu";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <NavMenu />
        <Container style={{ minHeight: "35vw" }}>
          {this.props.children}

        </Container>
        <div
          style={{
            flex: 1,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: 100,
            backgroundColor: "#4d4d4d",
            bottom: 0,
            position: "absolute",
            left: 0,
            right: 0
          }}
        >
          <a target="_blank" href="https://medex-covid19.com/impressum/" style={{ color: "white", textDecoration: "none" }}>Impressum</a>
          <a target="_blank" href="https://medex-covid19.com/datenschutzerklaerung/" style={{ color: "white", textDecoration: "none" }} >Datenschutz</a>
        </div>
      </div>
    );
  }
}
