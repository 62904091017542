import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  Row,
  ModalFooter,
} from "reactstrap";
import {
  updateAppointment,
  updateAppointmentClear,
} from "../store/updateAppointment/updateAppointment.actions";
import moment from "moment";

const UpdateAppointment = ({ isOpen, setOpen, data }) => {
  const [inputs, setInputs] = useState(data);

  useEffect(() => {
    setInputs(data);
  }, [data]);

  const { loading, success } = useSelector(
    (state) => state.request.updateAppointment
  );

  const getBooleanFromStringOrBoolean = (value) => {
    if (value == null || value == "-") {
      return null;
    }

    if (typeof value == "boolean") {
      return value;
    }

    return value == "true";
  };

  const dispatch = useDispatch();
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateAppointment(
        data.id,
        inputs.firstName,
        inputs.lastName,
        inputs.email,
        inputs.dateOfBirth !== data.dateOfBirth ? moment(inputs.dateOfBirth, "DD.MM.YYYY").set("hour", 12) : inputs.dateOfBirth,
        inputs.dateForTest !== data.dateForTest ? moment(inputs.dateForTest, "DD.MM.YYYY").set("hour", 12) : inputs.dateForTest,
        inputs.testerName,
        getBooleanFromStringOrBoolean(inputs.consentGiven),
        inputs.dateOfTransmission !== data.dateOfTransmission ? moment(inputs.dateOfTransmission, "DD.MM.YYYY").set("hour", 12) : inputs.dateOfTransmission,
        parseInt(inputs.formOfConsent),
        getBooleanFromStringOrBoolean(inputs.hasCovid),
        inputs.street,
        inputs.houseNumber,
        inputs.postCode,
        inputs.location,
        inputs.phoneNumber
      )
    );
  };

  useEffect(() => {
    if (success) {
      setOpen(false);
      dispatch(updateAppointmentClear());
    }
  }, [success]);

  return (
    <div style={{ width: "100%" }}>
      <Modal isOpen={isOpen}>
        <ModalHeader>Termin bearbeiten (Lfd. Nr. {data.reference})</ModalHeader>
        <Form className="col-12" onSubmit={onSubmit} style={{ marginTop: 15 }}>
          <Row form>
            <FormGroup className="col-6">
              <Label for="firstName">Vorname*</Label>
              <Input
                onChange={(e) => {
                  setInputs({ ...inputs, firstName: e.target.value });
                }}
                type="text"
                name="firstName"
                required
                value={inputs.firstName}
              />
            </FormGroup>
            <FormGroup className="col-6">
              <Label for="lastName">Nachname*</Label>
              <Input
                type="text"
                name="lastName"
                required
                onChange={(e) =>
                  setInputs({ ...inputs, lastName: e.target.value })
                }
                value={inputs.lastName}
              />
            </FormGroup>
          </Row>
          <Row form>
            <FormGroup className="col-6">
              <Label for="street">Straße*</Label>
              <Input
                onChange={(e) => {
                  setInputs({ ...inputs, street: e.target.value });
                }}
                type="text"
                name="street"
                required
                value={inputs.street}
              />
            </FormGroup>
            <FormGroup className="col-6">
              <Label for="houseNumber">Hausnummer*</Label>
              <Input
                type="text"
                name="houseNumber"
                required
                onChange={(e) =>
                  setInputs({ ...inputs, houseNumber: e.target.value })
                }
                value={inputs.houseNumber}
              />
            </FormGroup>
          </Row>
          <Row form>
            <FormGroup className="col-6">
              <Label for="postCode">Postleitzahl*</Label>
              <Input
                onChange={(e) => {
                  setInputs({ ...inputs, postCode: e.target.value });
                }}
                type="text"
                name="postCode"
                required
                value={inputs.postCode}
              />
            </FormGroup>
            <FormGroup className="col-6">
              <Label for="location">Ort*</Label>
              <Input
                type="text"
                name="location"
                required
                onChange={(e) =>
                  setInputs({ ...inputs, location: e.target.value })
                }
                value={inputs.location}
              />
            </FormGroup>
          </Row>
          <Row form>
            <FormGroup className="col-6">
              <Label for="dateOfBirth">Geburtstag*</Label>
              <Input
                name="dateOfBirth"
                required
                onChange={(e) =>
                  setInputs({ ...inputs, dateOfBirth: e.target.value })
                }
                value={
                  inputs.dateOfBirth === data.dateOfBirth
                    ? moment(inputs.dateOfBirth).format("DD.MM.YYYY")
                    : inputs.dateOfBirth
                }
                placeholder="tt.mm.jjjj"
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}"
              />
            </FormGroup>
            <FormGroup className="col-6">
              <Label for="dateForTest">Testtag*</Label>
              <Input
                name="dateForTest"
                onChange={(e) => {
                  setInputs({ ...inputs, dateForTest: e.target.value });
                }}
                value={
                  inputs.dateForTest === data.dateForTest
                    ? moment(inputs.dateForTest).format("DD.MM.YYYY")
                    : inputs.dateForTest
                }
                placeholder="tt.mm.jjjj"
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}"
              />
            </FormGroup>
          </Row>

          <Row form>
            <FormGroup className="col-6">
              <Label for="consentGiven">Einwilligung</Label>
              <Input
                type="select"
                name="consentGiven"
                onChange={(e) =>
                  setInputs({ ...inputs, consentGiven: e.target.value })
                }
                value={
                  inputs.consentGiven == null
                    ? "-"
                    : inputs.consentGiven.toString()
                }
              >
                <option value={"-"}>-</option>
                <option value={"true"}>Ja</option>
                <option value={"false"}>Nein</option>
              </Input>
            </FormGroup>

            <FormGroup className="col-6">
              <Label for="formOfConsent">Einwilligungsform</Label>
              <Input
                type="select"
                name="formOfConsent"
                onChange={(e) =>
                  setInputs({ ...inputs, formOfConsent: e.target.value })
                }
                value={inputs.formOfConsent}
              >
                <option value={0}>mündlich</option>
                <option value={1}>Email</option>
                <option value={2}>Schriftform</option>
                <option value={3}>Vertreter</option>
              </Input>
            </FormGroup>
          </Row>

          <Row form>
            <FormGroup className="col-6">
              <Label for="dateOfTransmission">Meldung GA</Label>
              <Input
                name="dateOfTransmission"
                onChange={(e) =>
                  setInputs({ ...inputs, dateOfTransmission: e.target.value })
                }
                value={
                  inputs.dateOfTransmission === data.dateOfTransmission &&
                  inputs.dateOfTransmission
                    ? moment(inputs.dateOfTransmission).format("DD.MM.YYYY")
                    : inputs.dateOfTransmission
                }
                placeholder="tt.mm.jjjj"
              ></Input>
            </FormGroup>
            <FormGroup className="col-6">
              <Label for="hasCovid">Testergebnis</Label>
              <Input
                type="select"
                onChange={(e) =>
                  setInputs({ ...inputs, hasCovid: e.target.value })
                }
                name="hasCovid"
                value={
                  inputs.hasCovid == null ? "-" : inputs.hasCovid.toString()
                }
              >
                <option value={"-"}>-</option>
                <option value={"false"}>Negativ</option>
                <option value={"true"}>Positiv</option>
              </Input>
            </FormGroup>
          </Row>

          <Row form>
            <FormGroup className="col-6">
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                onChange={(e) =>
                  setInputs({ ...inputs, email: e.target.value })
                }
                value={inputs.email}
              />
            </FormGroup>
            <FormGroup className="col-6">
              <Label for="testerName">Testername</Label>
              <Input
                type="select"
                name="testerName"
                onChange={(e) =>
                  setInputs({ ...inputs, testerName: e.target.value })
                }
                value={
                  inputs.testerName == null ? "-" : inputs.testerName.toString()
                }
              >
                <option value={"-"}>-</option>
                <option value={"Skruth"}>Skruth</option>
                <option value={"Schwichtenberg"}>Schwichtenberg</option>
                <option value={"Dahlmeier"}>Dahlmeier</option>
                <option value={"Lauterbach"}>Lauterbach</option>
              </Input>
            </FormGroup>
            </Row>
            <Row form>
                <FormGroup className="col-6">
                    <Label for="phoneNumber">Telefonnummer</Label>
                    <Input
                        name="phoneNumber"
                        onChange={(e) =>
                            setInputs({ ...inputs, phoneNumber: e.target.value })
                        }
                        value={inputs.phoneNumber}
                    />
                </FormGroup>
            </Row>
          <ModalFooter>
            <Button
              color="success"
              type="submit"
              style={{ marginTop: 20, marginBottom: 20 }}
              disabled={loading}
            >
              {loading ? "Daten werden übermittelt..." : "Ändern"}
            </Button>
            <Button
              onClick={() => setOpen(false)}
              type="button"
              color="secondary"
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              Abbrechen
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdateAppointment;
