import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import {
  createAppointment,
  createAppointmentClear,
} from "../store/createAppointment/createAppointment.actions";
import moment from "moment";
const MEDEX = require("../assets/MEDEX_Logo.jpg");
const Tankwirt = require("../assets/tankwirt_logo.png");
const Shadet = require("../assets/shadet_logo.png");

const Home = () => {
  const defaultState = {
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    dateForTest: "",
    location: "",
    postCode: "",
    street: "",
    houseNumber: "",
    phoneNumber: "",
  };

  const [inputs, setInputs] = useState(defaultState);
  const dispatch = useDispatch();
  const onSubmit = (e) => {
    e.preventDefault();

    const startBoundary = moment("2022.12.24", "YYYY.MM.DD");
    const endBoundary = moment("2022.12.26", "YYYY.MM.DD");

    const secondStartBoundary = moment("2022.12.31", "YYYY.MM.DD");
    const secondEndBoundary = moment("2023.1.1", "YYYY.MM.DD");

    const actualDateForTest = moment(inputs.dateForTest, "DD.MM.YYYY").set("hour", 12);
    if (
      moment(actualDateForTest).isBetween(
        startBoundary,
        endBoundary,
        "day",
        "[]"
      ) || moment(actualDateForTest).isBetween(
        secondStartBoundary,
        secondEndBoundary,
        "day",
        "[]")
      || (moment(actualDateForTest).isAfter(moment("2022.11.30", "YYYY.MM.DD")) &&
        moment(actualDateForTest).weekday() === 2
        || moment(actualDateForTest).weekday() === 4
        || moment(actualDateForTest).weekday() === 6)

    ) {
      alert("An diesem Datum haben wir geschlossen");
      return;
    }

    dispatch(
      createAppointment(
        inputs.firstName,
        inputs.lastName,
        inputs.email,
        moment(inputs.dateOfBirth, "DD.MM.YYYY").set("hour", 12),
        moment(inputs.dateForTest, "DD.MM.YYYY").set("hour", 12),
        inputs.street,
        inputs.houseNumber,
        inputs.postCode,
        inputs.location,
        inputs.phoneNumber
      )
    );
  };

  const { success, loading } = useSelector(
    (state) => state.request.createAppointment
  );

  useEffect(() => {
    setInputs(defaultState);
    if (success) {
      alert(
        "Sie haben sich erfolgreich angemeldet! \nWir erwarten Sie am angegebenen Testtag unter der Adresse bei Tankwirt (Dürener Straße 56, 53501 Gelsdorf)"
      );
      dispatch(createAppointmentClear());
    }
  }, [success]);

  return (
    <div style={{ width: "100%" }}>
      <h1 style={{ marginTop: 50, marginBottom: 20, textAlign: "center" }}>Vorübergehend geschlossen</h1>
      <h3 style={{ marginBottom: 20, textAlign: "center" }}>Wir bedanken uns für Ihr Vertrauen in uns. Bleiben Sie gesund!</h3>

      <Row
        style={{
          display: "flex",
          alignItems: "center",
          margin: 0,
          padding: 0,
          marginTop: 20,
          justifyContent: "space-around"
        }}
      >
        <img src={MEDEX} height={100} />
        <img src={Tankwirt} height={200} />
        <img src={Shadet} height={80} />
      </Row>

      {/* <Row>
        <Form className="col-12 col-md-6" onSubmit={onSubmit}>
          <FormGroup>
            <Label for="firstName">Vorname*</Label>
            <Input
              onChange={(e) => {
                setInputs({ ...inputs, firstName: e.target.value });
              }}
              type="text"
              name="firstName"
              placeholder="Max"
              required
              value={inputs.firstName}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastName">Nachname*</Label>
            <Input
              type="text"
              name="lastName"
              placeholder="Mustermann"
              required
              onChange={(e) =>
                setInputs({ ...inputs, lastName: e.target.value })
              }
              value={inputs.lastName}
            />
          </FormGroup>
          <FormGroup>
            <Label for="testdate">Geburtstag*</Label>
            <Input
              type="text"
              name="date"
              required
              onChange={(e) =>
                setInputs({ ...inputs, dateOfBirth: e.target.value })
              }
              value={inputs.dateOfBirth}
              placeholder="tt.mm.jjjj"
              pattern="(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}"
            />
          </FormGroup>
          <FormGroup>
            <Label for="testdate">Testtag*</Label>
            <Input
              name="date"
              required
              onChange={(e) =>
                setInputs({ ...inputs, dateForTest: e.target.value })
              }
              value={inputs.dateForTest}
              placeholder="tt.mm.jjjj"
              pattern="(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}"
            />
          </FormGroup>

          <FormGroup>
            <Label for="street">Straße*</Label>
            <Input
              type="text"
              name="street"
              placeholder="Musterstraße"
              required
              onChange={(e) => setInputs({ ...inputs, street: e.target.value })}
              value={inputs.street}
            />
          </FormGroup>

          <FormGroup>
            <Label for="houseNumber">Hausnummer*</Label>
            <Input
              type="text"
              name="houseNumber"
              placeholder="1a"
              required
              onChange={(e) =>
                setInputs({ ...inputs, houseNumber: e.target.value })
              }
              value={inputs.houseNumber}
            />
          </FormGroup>

          <FormGroup>
            <Label for="postCode">Postleitzahl*</Label>
            <Input
              type="text"
              name="postCode"
              placeholder="00000"
              required
              onChange={(e) =>
                setInputs({ ...inputs, postCode: e.target.value })
              }
              value={inputs.postCode}
            />
          </FormGroup>

          <FormGroup>
            <Label for="location">Ort*</Label>
            <Input
              type="text"
              name="location"
              placeholder="Musterstadt"
              required
              onChange={(e) =>
                setInputs({ ...inputs, location: e.target.value })
              }
              value={inputs.location}
            />
          </FormGroup>

          <FormGroup>
            <Label for="email">
              Email (nur anzugeben, wenn Ergebnis per Mail versendet werden
              soll)
            </Label>
            <Input
              type="email"
              name="email"
              placeholder="max@mustermann.de"
              onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
              value={inputs.email}
            />
          </FormGroup>

          <FormGroup>
            <Label for="phoneNumber">
              Telefonnummer*
            </Label>
            <Input
              name="phoneNumber"
              placeholder="123456789"
              onChange={(e) => setInputs({ ...inputs, phoneNumber: e.target.value })}
              value={inputs.phoneNumber}
              required
            />
          </FormGroup>


          <FormGroup check>
            <Input type="checkbox" name="check" id="exampleCheck" required />
            <Label for="exampleCheck" check>
              Ich habe die{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.PUBLIC_URL + "/DatenschutzTestung.pdf"}`}
              >
                Einverständniserklärung
              </a>{" "}
              gelesen und akzeptiere diese.
            </Label>
          </FormGroup>

          <Button
            disabled={loading}
            type="submit"
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            {loading ? "Daten werden übermittelt..." : "Zum Test anmelden"}
          </Button>
        </Form>
        <div
          className="col-md-4 col-12 ml-md-5"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {!moment().isAfter(moment("2023.1.2")) &&
            <span style={{ fontSize: 20, color: "red" }}>
              Geschlossen an Weihnachten inklusive Heiligabend (24.12-26.12), sowie an Silvester & Neujahr{" "}
            </span>
          }

          {!moment().isAfter(moment("2022.11.30")) && (
            <>
              <h4 style={{ marginTop: 20 }}>Öffnungszeiten</h4>
              <div style={{ fontSize: 20 }}>Montag bis Freitag: 19-20 Uhr</div>
              <div style={{ fontSize: 20 }}>Samstags: 18-19 Uhr</div>
              <div style={{ fontSize: 20 }}>Sonn- und Feiertag: 10-12 Uhr</div>
            </>)}


          <h4 style={{ marginTop: 20 }}>Öffnungszeiten ab 01.12.2022</h4>
          <div style={{ fontSize: 20 }}>Montag: 19-20 Uhr</div>
          <div style={{ fontSize: 20 }}>Dienstag geschlossen</div>
          <div style={{ fontSize: 20 }}>Mittwoch 19-20 Uhr</div>
          <div style={{ fontSize: 20 }}>Donnerstag geschlossen</div>
          <div style={{ fontSize: 20 }}>Freitag 19-20 Uhr</div>
          <div style={{ fontSize: 20 }}>Samstag geschlossen</div>
          <div style={{ fontSize: 20 }}>Sonntag 10-12 Uh</div>

        </div>
      </Row> */}
    </div>
  );
};

export { Home };
