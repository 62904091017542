import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, Col, Row, Button, Input } from 'reactstrap';
import { getAllAppointments } from '../store/getAllAppointments/getAllAppointments.actions';
import SendTestResult from './SendTestResultModal';
import UpdateAppointment from './UpdateAppointment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getSentResultsCount } from '../store/getSentResultsCount/getSentResultsCount.actions';

const TestsOverview = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch(getAllAppointments());
        dispatch(getSentResultsCount());
    }, []);

    const data = useSelector((state) => state.appointment);
    const [updateAppointmentOpen, setUpdateAppointmentOpen] = useState(false);
    const [sendTestResultModalOpen, setSendTestResultModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [showOnlyNotDoneTest, setShowOnlyNotDoneTest] = useState(false);

    const todayRegistered = data.filter(
        (x) =>
            moment(x.dateForTest).format('DD.MM.YYYY') ===
            moment().format('DD.MM.YYYY')
    ).length;
    const todaySentEmails = data.filter(
        (x) =>
            moment(x.dateForTest).format('DD.MM.YYYY') ===
            moment().format('DD.MM.YYYY') && !!x.lastEmailSent
    ).length;

    const outstandingTests = todayRegistered - todaySentEmails;
    const overallTestsDone = useSelector((state) => state.request.getSentResultsCount.data)

    const onRowClick = (id) => {
        const newSelectedItem = data.find((x) => x.id === id);
        setSelectedItem(newSelectedItem);
        setUpdateAppointmentOpen(true);
    };
    const rowEvents = {
        onClick: (_, row) => onRowClick(row.id),
    };
    const columns = [
        {
            dataField: 'reference',
            text: 'Lfd. Nr',
            sort: true,
            headerStyle: { width: '7%' },
        },
        {
            dataField: 'dateForTest',
            text: 'Testtag',
            sort: true,
            formatter: (x) => (x ? moment(x).format('DD.MM.YYYY') : '-'),
        },
        {
            dataField: 'lastName',
            text: 'Name',
            sort: true,
            classes: 'ellipsis',
        },
        {
            dataField: 'dateOfBirth',
            text: 'Geburts \n datum',
            sort: true,
            formatter: (x) => moment(x).format('DD.MM.YYYY'),
        },
        {
            dataField: 'consentGiven',
            text: 'Einwilligung',
            sort: true,
            formatter: (x) => {
                if (x === true) return 'Ja';
                if (x === false) return 'Nein';
                return '';
            },
        },
        {
            dataField: 'hasCovid',
            text: 'Ergebnis',
            sort: true,
            formatter: (x) => {
                if (x === true) return 'Positiv';
                if (x === false) return 'Negativ';
                return '';
            },
        },
        {
            dataField: 'dateOfTransmission',
            text: 'Datum GA',
            sort: true,
            formatter: (x) => (x ? moment(x).format('DD.MM.YYYY') : '-'),
        },
        {
            dataField: 'testerName',
            text: 'Testername',
            sort: true,
            classes: 'ellipsis',
        },
        {
            dataField: 'email',
            text: 'Ergebnis senden',
            formatter: (x) =>
                x === 'testcenter@shadet.de' ? '-' : <Button>Senden</Button>,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    e.stopPropagation();
                    setSelectedItem(row);
                    setSendTestResultModalOpen(true);
                },
            },
        },
        {
            dataField: 'lastEmailSent',
            text: 'Datum Email',
            sort: true,
            formatter: (x) => (x ? moment(x).format('DD.MM.YYYY HH:mm') : '-'),
        },
    ];
    const { SearchBar } = Search;
    const currentData = data ? data : [];
    const allData = showOnlyNotDoneTest
        ? currentData.filter((x) => !x.lastEmailSent)
        : currentData;
    return (
        <Container className="mt-3">
            <ToolkitProvider
                keyField="id"
                data={allData}
                columns={columns}
                search={{ searchFormatted: true }}
            >
                {(props) => (
                    <div>
                        <Row>
                            <Col
                                className="col-md-6 col-12"
                                style={{
                                    alignItems: 'center',
                                    fontSize: '16px',
                                }}
                            >
                                <div>
                                    Insgesamt (rausgeschickt): {overallTestsDone} <br />
                                    Angemeldet (heute): {todayRegistered} Bearbeitet (heute):{' '}
                                    {todaySentEmails} Ausstehend (heute): {outstandingTests}
                                </div>
                            </Col>
                            <Col className="col-md-4 col-12 mt-3 mt-md-0">
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ marginRight: 10 }}>
                                        <Input
                                            type="checkbox"
                                            value={showOnlyNotDoneTest}
                                            onChange={() =>
                                                setShowOnlyNotDoneTest(!showOnlyNotDoneTest)
                                            }
                                        />
                                        Bearbeitete ausblenden
                                    </div>
                                    <Button onClick={() => history.push('/')}>Manuell</Button>
                                </div>
                            </Col>
                            <Col className="col-md-2 col-12 mt-3 mt-md-0">
                                <SearchBar
                                    {...props.searchProps}
                                    placeholder="Suchen"
                                    style={{ float: 'right' }}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <div className="noselect" style={{ cursor: 'pointer' }}>
                            <BootstrapTable
                                {...props.baseProps}
                                hover
                                rowEvents={rowEvents}
                                wrapperClasses="table-responsive"
                                defaultSorted={[{ dataField: 'dateForTest', order: 'desc' }]}
                                pagination={paginationFactory({ sizePerPage: 50 })}
                            />
                        </div>
                    </div>
                )}
            </ToolkitProvider>
            {selectedItem && (
                <UpdateAppointment
                    isOpen={updateAppointmentOpen}
                    data={selectedItem}
                    setOpen={setUpdateAppointmentOpen}
                />
            )}
            {selectedItem && (
                <SendTestResult
                    isOpen={sendTestResultModalOpen}
                    setOpen={setSendTestResultModalOpen}
                    id={selectedItem.id}
                    email={selectedItem.email}
                    reference={selectedItem.reference}
                />
            )}
        </Container>
    );
};

export default TestsOverview;