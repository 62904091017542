import { all, fork } from "redux-saga/effects";
import createAppointment from "./createAppointment/createAppointment.saga";
import getAllAppointments from "./getAllAppointments/getAllAppointments.saga";
import updateAppointment from "./updateAppointment/updateAppointment.saga";
import finalizeAppointment from "./finalizeAppointment/finalizeAppointment.saga";
import login from "./login/login.saga";
import getSentResultsCount from "./getSentResultsCount/getSentResultsCount.saga"

export default function* () {
    yield all([
        fork(createAppointment),
        fork(getAllAppointments),
        fork(updateAppointment),
        fork(finalizeAppointment),
        fork(login),
        fork(getSentResultsCount)
    ]);
}