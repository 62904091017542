import {
  createReducer,
  updateArray,
  pushToArray,
  updateItemInArray,
} from "./reducer.helper";
import { GET_ALL_APPOINTMENTS_SUCCESS } from "./getAllAppointments/getAllAppointments.actions";
import { CREATE_APPOINTMENT_SUCCESS } from "./createAppointment/createAppointment.actions";
import { UPDATE_APPOINTMENT_SUCCESS } from "./updateAppointment/updateAppointment.actions";

export default createReducer(
  {
    [GET_ALL_APPOINTMENTS_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload }),
    [CREATE_APPOINTMENT_SUCCESS]: (state, action) =>
      pushToArray(state, { ...action.payload }),
    [UPDATE_APPOINTMENT_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload),
  },
  []
);
