import { put, takeLatest, call } from 'redux-saga/effects';
import {
  UPDATE_APPOINTMENT,
  updateAppointmentSuccess,
  updateAppointmentFailure,
} from './updateAppointment.actions';
import API from '../../components/api-authorization/API';

function* updateAppointment({
  id,
  firstName,
  lastName,
  email,
  dateOfBirth,
  dateForTest,
  testerName,
  consentGiven,
  dateOfTransmission,
  formOfConsent,
  hasCovid,
  street,
  houseNumber,
  postCode,
  location,
  phoneNumber
}) {
  try {
    const data = {
      firstName,
      lastName,
      email,
      dateOfBirth: dateOfBirth ? dateOfBirth : null,
      dateForTest: dateForTest ? dateForTest : null,
      testerName,
      consentGiven,
      dateOfTransmission: dateOfTransmission ? dateOfTransmission : null,
      formOfConsent,
      hasCovid,
      street,
      houseNumber,
      postCode,
      location,
      phoneNumber
    };
    yield call(API.put, `/api/appointment/${id}`, {
      ...data,
    });

    yield put(updateAppointmentSuccess({ ...data }));
  } catch (error) {
    console.log(error);
    yield put(updateAppointmentFailure());
  }
}

export default function* () {
  yield takeLatest(UPDATE_APPOINTMENT, updateAppointment);
}
