import {
    createReducer,
    defaultRequest,
    defaultSuccess,
    defaultError,
    defaultInitialState,
} from "../reducer.helper";

import {
    GET_SENT_RESULTS_COUNT,
    GET_SENT_RESULTS_COUNT_SUCCESS,
    GET_SENT_RESULTS_COUNT_FAILURE,
    GET_SENT_RESULTS_COUNT_CLEAR,
} from "./getSentResultsCount.actions";

export default createReducer(
    {
        [GET_SENT_RESULTS_COUNT]: defaultRequest,
        [GET_SENT_RESULTS_COUNT_SUCCESS]: defaultSuccess,
        [GET_SENT_RESULTS_COUNT_FAILURE]: defaultError,
        [GET_SENT_RESULTS_COUNT_CLEAR]: () => defaultInitialState
    },
    defaultInitialState
);