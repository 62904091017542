import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
} from "../reducer.helper";

import {
  CREATE_APPOINTMENT,
  CREATE_APPOINTMENT_SUCCESS,
  CREATE_APPOINTMENT_FAILURE,
  CREATE_APPOINTMENT_CLEAR,
} from "./createAppointment.actions";

export default createReducer(
  {
    [CREATE_APPOINTMENT]: defaultRequest,
    [CREATE_APPOINTMENT_SUCCESS]: defaultSuccess,
    [CREATE_APPOINTMENT_FAILURE]: defaultError,
    [CREATE_APPOINTMENT_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
