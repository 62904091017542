import {
    createReducer,
    defaultRequest,
    defaultSuccess,
    defaultError,
    defaultInitialState,
  } from "../reducer.helper";
  
  import {
    FINALIZE_APPOINTMENT,
    FINALIZE_APPOINTMENT_SUCCESS,
    FINALIZE_APPOINTMENT_FAILURE,
  } from "./finalizeAppointment.actions";
  
  export default createReducer(
    {
      [FINALIZE_APPOINTMENT]: defaultRequest,
      [FINALIZE_APPOINTMENT_SUCCESS]: defaultSuccess,
      [FINALIZE_APPOINTMENT_FAILURE]: defaultError,
    },
    defaultInitialState
  );
  
  