import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { finalizeAppointment } from "../store/finalizeAppointment/finalizeAppointment.actions";
import moment from "moment";

const SendTestResult = ({ isOpen, setOpen, reference, email, id }) => {
  const dispatch = useDispatch();
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(finalizeAppointment(id));
  };

  const { loading, success } = useSelector(
    (state) => state.request.finalizeAppointment
  );

  useEffect(() => {
    if (success) {
      setOpen(false);
      alert("E-Mail erfolgreich versendet.");
    }
  }, [success]);

  return (
    <div style={{ width: "100%" }}>
      <Modal isOpen={isOpen}>
        <ModalHeader>Testergebnis (Lfd. Nr. {reference}) senden?</ModalHeader>
        <ModalBody>
          Soll das Testergebnis jetzt an den Getesteten (Email: {email})
          versendet werden?
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            type="submit"
            style={{ marginTop: 20, marginBottom: 20 }}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? "Daten werden übermittelt..." : "Jetzt senden"}
          </Button>
          <Button
            onClick={() => setOpen(false)}
            type="button"
            color="secondary"
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            Abbrechen
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SendTestResult;
