const environment = process.env.NODE_ENV;

const config = {
  ApiUrl: '',
  environment: ''
};

if (environment === 'development') {
  config.ApiUrl = 'https://localhost:5001';
  config.environment = 'development';
} else if (environment === 'production') {
  config.ApiUrl = 'https://corona-drive-in-gelsdorf.de';
  config.environment = 'production';
} else if (environment === 'test') {

} else {
  console.error('NODE_ENV is wrong, It is neither development or production.');
}

export default config;