import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_ALL_APPOINTMENTS,
  getAllAppointmentsSuccess,
  getAllAppointmentsFailure,
} from "./getAllAppointments.actions";
import API from "../../components/api-authorization/API";

function* getAllAppointments() {
  try {
    const {data} = yield call(API.get, "/api/appointment");

    yield put(getAllAppointmentsSuccess(data));
  } catch (error) {
      console.log(error);
    yield put(getAllAppointmentsFailure());
  }
}

export default function* () {
  yield takeLatest(GET_ALL_APPOINTMENTS, getAllAppointments);
}
