import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import TestsOverview from './components/TestsOverview';
import Login from './components/Login';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route exact path='/overview' component={TestsOverview} />
        <Route exact path='/login' component={Login} />
      </Layout>
    );
  }
}
