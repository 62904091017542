import { put, takeLatest, call } from 'redux-saga/effects';
import API from '../../components/api-authorization/API';
import { LOGIN, loginSuccess, loginFailure } from './login.actions';

export function* login({ email, password }) {
  try {
    const { data } = yield call(API.post, '/api/appointment/login', {
      email,
      password,
    });

    localStorage.setItem('access_token', data.token);
    yield put(loginSuccess());
    window.location = '/overview';
  } catch (error) {
    yield put(loginFailure());
  }
}

export default function* () {
  yield takeLatest(LOGIN, login);
}
