export const GET_SENT_RESULTS_COUNT = 'GET_SENT_RESULTS_COUNT';
export const GET_SENT_RESULTS_COUNT_SUCCESS = 'GET_SENT_RESULTS_COUNT_SUCCESS';
export const GET_SENT_RESULTS_COUNT_FAILURE = 'GET_SENT_RESULTS_COUNT_FAILURE';
export const GET_SENT_RESULTS_COUNT_CLEAR = 'GET_SENT_RESULTS_COUNT_CLEAR';

export const getSentResultsCount = (
    firstName,
    lastName,
    email,
    dateOfBirth,
    dateForTest,
    street,
    houseNumber,
    postCode,
    location,
    phoneNumber
) => ({
    type: GET_SENT_RESULTS_COUNT,
    firstName,
    lastName,
    email,
    dateOfBirth,
    dateForTest,
    street,
    houseNumber,
    postCode,
    location,
    phoneNumber
});
export const getSentResultsCountSuccess = (payload) => ({
    type: GET_SENT_RESULTS_COUNT_SUCCESS,
    payload,
});
export const getSentResultsCountFailure = (errors) => ({
    type: GET_SENT_RESULTS_COUNT_FAILURE,
    errors,
});
export const getSentResultsCountClear = () => ({
    type: GET_SENT_RESULTS_COUNT_CLEAR,
});